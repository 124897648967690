<template>
  <div class="article-card" @click="onViewArticle(data?.PostKey)">
    <div v-if="data?.PostKey" class="content">
      <div v-if="!hideProfile" class="profile" @click.stop="onViewInfluencer(data?.UserKey)">
        <img loading="lazy" :src="$h.getImage(data?.UserImage, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
        <h3>{{ data?.UserName }}</h3>

        <div class="options">
          <f7-link @click.stop="likeToggle(data?.PostKey)">
            <font-awesome-icon v-if="data?.IsLike === 1" :icon="['fas', 'heart']" fixed-width class="fill" />
            <font-awesome-icon v-else :icon="['far', 'heart']" fixed-width />

            <span v-if="data?.TotalLike > 0 || data?.TotalDummyLike > 0">{{ $h.formatLikeCounter(data) }}</span>
          </f7-link>
        </div>
      </div>

      <div class="image">
        <img v-if="data?.PostImage" loading="lazy" :src="$h.getImage(data?.PostImage, 'POST')" :alt="$t.getTranslation('LBL_POST_IMAGE')" />
      </div>

      <div v-if="data?.PostTitle" class="content">
        <h1>{{ $h.formatTrimString(data?.PostTitle, 80) }}</h1>
      </div>

      <div v-if="data?.PostText" class="content">
        <p>{{ $h.formatTrimString(data?.PostText, 80) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, toRefs } from "vue";
import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

export default defineComponent({
  name: "ArticleCardComponent",
  components: {},
  props: {
    data: Object,
    hideProfile: {
      type: Boolean,
      default: false,
    },
    showProducts: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;

    const { data } = toRefs(props);

    const onViewArticle = (key) => {
      $f7router.navigate({ name: "articleViewPage", params: { key } });
    };

    const likeToggle = async (PostKey) => {
      if (!helpers.isUserLogin()) {
        $f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/post/like/toggle", { PostKey: PostKey });

      if (data.value.IsLike === 1) {
        data.value.IsLike = 0;
        data.value.TotalLike--;
      } else {
        data.value.IsLike = 1;
        data.value.TotalLike++;
      }
    };

    const onViewInfluencer = (userKey) => {
      $f7router.navigate({ name: "influencerViewPage", params: { code: userKey } });
    };

    return { onViewArticle, likeToggle, onViewInfluencer, store };
  },
});
</script>
